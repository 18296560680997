import React from 'react';
import PreviewListView from 'components/ui-components/PreviewListView';
import PreviewGridView from 'components/ui-components/PreviewGridView';
import EmptyState from 'components/ui-components-cape/EmptyState';
import Illustration from 'components/ui-components-cape/Illustration';
import Translation from 'components/data/Translation';
import SelectorViewType from '../interfaces/SelectorViewType';
import { SelectorViewItemType } from '..';

import '../styles/selector-view-content.scss';

interface Classes {
    root?: string;
    listView?: string;
    gridView?: string;
}

interface Props<SelectorViewItemType> {
    items: SelectorViewItemType[];
    viewType: SelectorViewType;
    classes?: Classes;
    onSelect: (item: SelectorViewItemType) => void;
}

/**
 * The component is responsible for rendering the content of the selector view.
 */
const SelectorViewContent = <T extends object>({ items, viewType, classes, onSelect }: Props<SelectorViewItemType<T>>) => {
    return (
        <div className={`selector-view-content ${classes?.root}`}>
            {items.length === 0 ? (
                <div className="selector-view-content__empty-state">
                    <EmptyState
                        primaryText={Translation.get('feedback.emptyState.noResults', 'common')}
                        illustration={<Illustration illustration="no-result" />}
                    />
                </div>
            ) : (
                <>
                    {viewType === 'list' && <PreviewListView className={classes?.listView} items={items} onSelect={onSelect} />}
                    {viewType === 'grid' && <PreviewGridView items={items} onSelect={(item) => onSelect(item)} />}
                </>
            )}
        </div>
    );
};

export default SelectorViewContent;
