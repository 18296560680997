import React, { useEffect, useState } from 'react';
import ComponentStore from 'components/data/ComponentStore';
import Translation from 'components/data/Translation';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import EmptyState from 'components/ui-components-cape/EmptyState';
import AssetGalleryStore from '../../../../../../../helpers/asset-gallery-store';
import SelectorGrid from '../../grid';

import '../../../../styles/asset-finder.scss';

interface CropSettings {
    outputHeight: number;
    outputWidth: number;
    maxOutputHeight: number;
    maxOutputWidth: number;
    mode?: string;
    ratios: string[];
    canSkipCropper?: boolean;
}

interface Props {
    onSelect: (item: SourceData, skipCropper?: boolean) => void;
    cropSettings: CropSettings;
}

/**
 *
 * This is the selector for reuse image
 */
const SelectorReuseImage: React.FC<Props> = ({ onSelect, cropSettings }) => {
    const [filteredGalleryStore, setFilteredGalleryStore] = useState([]);

    if (!ComponentStore.get('CreativeEditor').assetGalleryStore) AssetGalleryStore.init();
    const { assetGalleryStore } = ComponentStore.get('CreativeEditor');

    useEffect(() => {
        /**
         * Filters the assetGalleryStore on if it fits within the parameters given by the component
         * and stores it in state
         */
        async function filterGalleryResults() {
            const results = await Promise.all(assetGalleryStore.assetGalleryObjects.map((assetGalleryObject) => filterImageFitting(assetGalleryObject)));
            setFilteredGalleryStore(assetGalleryStore.assetGalleryObjects.filter((_v, index) => results[index]));
        }

        if (assetGalleryStore.assetGalleryUrls.length > 0) {
            filterGalleryResults();
        }
    }, []);

    /**
     * Checks if the image fits inside the parameters given to the gallery
     * @param {string} image Asset gallery object to filter
     * @returns if image fits in current gallery
     */
    const filterImageFitting = async (image) => {
        const outputWidth = cropSettings.maxOutputWidth ? cropSettings.maxOutputWidth : cropSettings.outputWidth;
        const outputHeight = cropSettings.maxOutputHeight ? cropSettings.maxOutputHeight : cropSettings.outputHeight;

        //If user can skip or there is no cropper then every image is permitted
        if (cropSettings.canSkipCropper || (outputWidth === 0 && outputHeight === 0)) return true;

        /**
         *
         * @param {string} url
         * @returns Meta data of the image
         */
        const getMeta = (url) =>
            new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = (err) => reject(err);
                img.src = url;
            });

        const imageMetaData: any = await getMeta(image.url);
        const imageAspect = Math.round((imageMetaData.naturalWidth / imageMetaData.naturalHeight) * 10) / 10;

        if ((imageMetaData.naturalWidth <= outputWidth && outputHeight === 0) || (imageMetaData.naturalHeight <= outputHeight && outputWidth === 0)) {
            //If only outputWidth/Height is present and the image width/height is below it
            return true;
        } else if (outputWidth > 0 && outputHeight > 0) {
            if (cropSettings.mode === 'free' && imageMetaData.naturalWidth <= outputWidth && imageMetaData.naturalHeight <= outputHeight) {
                // if mode is free check if size is correct
                return true;
            }

            //If both outputWidth and height are present check on aspect ratio and size
            const cropAspectCorrect = Math.round((outputWidth / outputHeight) * 10) / 10;

            if (imageAspect === cropAspectCorrect && imageMetaData.naturalWidth <= outputWidth && imageMetaData.naturalHeight <= outputHeight) {
                return true;
            }
        } else if (cropSettings.ratios) {
            //Check crop based on ratio
            const ratioCheckResult = cropSettings.ratios.find((ratio) => {
                const ratioNum = Math.round((Number(ratio.split(':')[0]) / Number(ratio.split(':')[1])) * 10) / 10;
                return imageAspect === ratioNum;
            });

            if (ratioCheckResult) return true;
        }

        return false;
    };

    /**
     * Selects the image (passed it up) and flags it as reused
     * @param {object} imageData
     */
    const onReuseImage = (imageData) => {
        onSelect({ ...imageData, reused: true });
    };

    return (
        <div className="asset-finder">
            <div className="asset-finder__gallery-store">
                {filteredGalleryStore.length > 0 ? (
                    <SelectorGrid hideDeleteButton={true} list={filteredGalleryStore} isLoading={false} isRefresh={false} onSelect={onReuseImage} />
                ) : (
                    <EmptyState primaryText={Translation.get('assetGalleryDialog.assetSelectView.noAssetsInCreative', 'content-space')} />
                )}
            </div>
        </div>
    );
};

export default SelectorReuseImage;
